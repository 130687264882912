import Vue from "vue";
import Router from "vue-router";
import { checkLineUser } from "@/config/line-service";
import { checkLineUserBypass } from "@/config/line-service-login-bypass";
import { loginLineUser } from "@/config/line-service-login";
import store from "@/store";
// import i18n from "./plugins/i18n";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        // ========== ========== Page Login ========== ==========
        {
          name: "check-login",
          path: "/",
          component: () => import("@/layouts/blank-layout/Blanklayout"),
          beforeEnter: checkLineUser,
          meta: { roleUser: [""] },
        },
        {
          name: "login",
          path: "login",
          component: () =>
            import("@/views/Customer/LoginWithLineOption/LoginWithOutOtp"),
          beforeEnter: loginLineUser,
          meta: { roleUser: [""] },
        },
        {
          name: "login-line",
          path: "login-line",
          component: () =>
            import("@/views/Customer/LoginWithLineOption/LoginWithOutOtp"),
          beforeEnter: checkLineUserBypass,
          meta: { roleUser: [""] },
        },
        {
          name: "login-bypass",
          path: "login-bypass",
          component: () =>
            import("@/views/Customer/LoginWithOtpOption/LoginByPassOtp"),
          meta: { roleUser: [""] },
        },
        {
          name: "login-user",
          path: "login-user",
          component: () =>
            import("@/views/Customer/LoginWithOtpOption/LoginWithOutOtp"),
          meta: { roleUser: ["Customer"] },
        },

        // ========== ========== Page Register ========== ==========
        {
          name: "register",
          path: "register",
          component: () =>
            import("@/views/Customer/LoginWithLineOption/RegisterUser"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "register-user",
          path: "register-user",
          component: () =>
            import(
              "@/views/Customer/LoginWithOtpOption/RegisterUserWithoutOtp"
            ),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "register-bypass",
          path: "register-bypass",
          component: () =>
            import("@/views/Customer/LoginWithOtpOption/RegisterPhone"),
          meta: { roleUser: [""] },
        },

        // ========== ========== Page Check Number ========== ==========
        {
          name: "checknumber",
          path: "checknumber",
          component: () =>
            import("@/views/Customer/LoginWithLineOption/CheckNumber"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "checknumberbyline",
          path: "checknumberbyline",
          component: () =>
            import("@/views/Customer/LoginWithLineOption/LoginWithOutOtp"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "checknumberbyotp",
          path: "checknumberbyotp",
          component: () =>
            import("@/views/Customer/LoginWithOtpOption/CheckNumberOtp"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "checknumberLine",
          path: "checknumberLine",
          component: () =>
            import("@/views/Customer/LoginWithLineOption/CheckNumberLine"),
          meta: { roleUser: ["Customer"] },
        },

        // ========== ========== Page Detail User ========== ==========
        {
          name: "detail-user",
          path: "detail-user",
          component: () =>
            import("@/views/Customer/LoginWithLineOption/DetailUser"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "detail-user-otp",
          path: "detail-user-otp",
          component: () =>
            import("@/views/Customer/LoginWithOtpOption/DetailUserOtp"),
          meta: { roleUser: ["Customer"] },
        },

        // ========== ========== Page For Main ========== ==========
        {
          name: "search",
          path: "search",
          component: () => import("@/components/searchPage"),
          meta: { roleUser: ["Customer"] },
        },

        // ========== ========== Page For Mission ========== ==========
        {
          name: "activity-coming",
          path: "activity-coming",
          component: () =>
            import("@/components/activityComing/activityComing.vue"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "activity-recommended",
          path: "activity-recommended",
          component: () =>
            import("@/components/activityRecommended/activityRecommended.vue"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "mission-detail",
          path: "missiondetail/:id",
          component: () => import("@/views/Customer/Mission/MissionDetail"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "mission-upload",
          path: "missionupload/:id",
          component: () => import("@/views/Customer/Mission/MissionUpload"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "mission-checkin",
          path: "missioncheckin/:id",
          component: () => import("@/views/Customer/Mission/MissionCheckIn"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "mission-survey",
          path: "missionsurvey/:id",
          component: () => import("@/views/Customer/Mission/MissionSurvey"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "mission-heart-to-heart",
          path: "missionhearttoheart/:id",
          component: () => import("@/views/Customer/Mission/MissionHeartToHeart"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "my-mission-detail",
          path: "mymission/detail/:id",
          component: () => import("@/views/Customer/Mission/MyMissionDetail"),
          meta: { roleUser: ["Customer"] },
        },

        // ========== ========== Page For My Account ========== ==========
        {
          name: "my-history",
          path: "history",
          component: () => import("@/views/Customer/History/MyHistory"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "transfer-coin",
          path: "transfer-coin",
          component: () => import("@/views/Customer/History/TransferCoin"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "privacy",
          path: "privacy",
          component: () => import("@/views/Customer/Setting/Privacy"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "favorite-item",
          path: "favorite-item",
          component: () => import("@/views/Customer/Favorite/FavoritePage"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "list-address",
          path: "list-address",
          component: () => import("@/views/Customer/Address/ListAddress"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "add-address",
          path: "add-address",
          component: () => import("@/views/Customer/Address/AddAddress"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "my-coupons",
          path: "my-coupons",
          component: () => import("@/views/Customer/MyCoupons/MyCoupons"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "history-coupon",
          path: "history-coupon",
          component: () => import("@/views/Customer/MyCoupons/HistoryMyCoupon"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "invite-friend",
          path: "invite-friend",
          component: () => import("@/views/Customer/Friend/Invite"),
          meta: { roleUser: ["Customer"] },
        },

        // ========== ========== Page For Blog Reward Shipment Other ========== ==========
        {
          name: "all-blog",
          path: "all-blog",
          component: () =>
            import("@/components/allBlog.vue"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "blog",
          path: "blog/:id",
          component: () => import("@/views/Customer/Blog/Blog"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "reward-so-hot",
          path: "reward-so-hot",
          component: () => import("@/views/Customer/Reward/RewardSoHot"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "reward/list-address",
          path: "reward/list-address",
          component: () => import("@/views/Customer/Reward/ListAddress"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "cancel-shipment",
          path: "cancel-shipment",
          component: () => import("@/views/Customer/Transport/CancelPage"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "mail-box",
          path: "mail-box",
          component: () => import("@/views/Customer/Mail/HeartToHeart"),
          meta: { roleUser: ["Customer"] },
        },

        // ========== ========== Page Not Found ========== ==========
        {
          name: "page-not-found",
          path: "/",
          component: () => import("@/views/PageNotFound"),
          meta: { roleUser: [""] },
        },
        {
          name: "page-not-found-line",
          path: "/error-not-found-line",
          component: () => import("@/views/PageLineNotFound"),
          meta: { roleUser: [""] },
        },
      ],
    },
    {
      path: "",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "main",
          path: "main",
          component: () => import("@/views/Customer/Main"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "mission",
          path: "mission",
          component: () => import("@/views/Customer/Mission/Mission"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "my-mission",
          path: "mymission/:status",
          component: () => import("@/views/Customer/Mission/MyMission"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "reward",
          path: "reward",
          component: () => import("@/views/Customer/Reward/RewardPage"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "transport",
          path: "transport",
          component: () => import("@/views/Customer/Transport/TransportPage"),
          meta: { roleUser: ["Customer"] },
        },
        {
          name: "my-account",
          path: "account",
          component: () => import("@/views/Customer/MyAccount/MyAccount"),
          meta: { roleUser: ["Customer"] },
        },
      ],
    },

    // ========== ========== Page Not Found ========== ==========
    {
      path: "*",
      name: "error",
      component: () => import("@/views/Error"),
      meta: {
        roleUser: ["Root", "Super Admin", "Admin", "Customer"],
      },
      beforeEnter: (to, from, next) => {
        next({ name: "check-login" });
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  let role = store.state.defaultSystem.user;
  let roleName = "";
  if (role == null) {
    roleName = "Unknown";
  } else {
    roleName = role.role;
  }

  if (!to.meta.roleUser[0]) {
    return next();
  }

  let checkRoleRoutes = to.meta.roleUser
    ? to.meta.roleUser.includes(roleName)
    : next({ name: "check-login" });

  if (checkRoleRoutes) {
    return next();
  } else {
    return next({ name: "check-login" });
  }
});

export default router;
