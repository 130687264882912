import axios from "axios";
import router from "@/router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import i18n from "../plugins/i18n";

let userStorage;

const BackendService = axios.create({
  baseURL: process.env.VUE_APP_CUSTOMER_SERVICE_MOBILE,
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
});

BackendService.interceptors.request.use(async (config) => {
  let token = "";
  try {
    userStorage = localStorage.getItem("token");
    token = userStorage;
    //set lang
    config.headers["X-localization"] = i18n.locale;
  } catch (error) {
    console.log(error);
  }
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

BackendService.interceptors.response.use(undefined, function(error) {
  if (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
                
      setTimeout(() => {
        return router.push({
          name: "check-login",
        });
      }, 3000);
    }
    if (error.response.status === 400) {
      if (
        error.response.data.message == "ขออภัย สิทธิ์การเล่นกิจกรรมเต็มแล้ว"
      ) {
        Swal.fire({
          icon: "warning",
          text: error.response.data.message,
          showConfirmButton: true,
          confirmButtonColor: "orange",
          confirmButtonText: "ตกลง",
        });
      } else {
        Swal.fire({
          icon: "error",
          // title: "Oops! error",
          text: error.response.data.message,
          showConfirmButton: true,
          confirmButtonColor: "red",
          confirmButtonText: "ตกลง",
        });
      }
    }
    if (error.response.status === 404) {
      return router.push({ name: "page-not-found" });
    }
    throw error;
  }
});

export default BackendService;
